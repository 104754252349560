import NextHead from "next/head";
import Script from "next/script";

export interface IProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  canonical?: string;
  robots?: "noindex" | "nofollow" | "noindex, nofollow";
  children?: React.ReactNode | React.ReactNode[];
}

const Head = (props: IProps) => {
  return (
    <NextHead>
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />

      {props.title && (
        <>
          <title>{props.title}</title>
          <meta property="og:title" content={props.title} />
          <meta name="twitter:title" content={props.title} />
        </>
      )}

      {props.description && (
        <>
          <meta name="description" content={props.description} />
          <meta property="og:description" content={props.description} />
          <meta name="twitter:description" content={props.description} />
        </>
      )}

      {props.image && (
        <>
          <meta name="image" content={props.image} />
          <meta property="og:image" content={props.image} />
          <meta name="twitter:image" content={props.image} />
        </>
      )}

      {props.url && (
        <>
          <meta property="og:url" content={props.url} />
          <meta property="twitter:url" content={props.url} />
        </>
      )}

      {props.canonical && (
        <>
          <link rel="canonical" href={props.canonical} />
        </>
      )}

      {props.robots && (
        <>
          <meta name="robots" content={props.robots} />
        </>
      )}
      {props.children}

      <script
        id={`Cookiebot`}
        async={true}
        src={`https://consent.cookiebot.com/uc.js`}
        data-cbid={`6fc722e8-ef52-4201-a55f-5193f9e2f73b`}
        data-blockingmode={`auto`}
        type={`text/javascript`}
      ></script>

      <script
        id={`Adsense`}
        async={true}
        src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3783681554158567`}
        type={`text/javascript`}
        crossOrigin={"anonymous"}
      ></script>

      <meta name="google-adsense-account" content="ca-pub-3783681554158567" />
    </NextHead>
  );
};

export default Head;
